import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import type {
  VehicleTelemetry,
  WebSocketVehicleMetadata,
} from '@/data/fms/vehicle/types';
import type { Schedule } from '@/data/fms/schedule/types';
import { useAuthToken } from '../auth';
import { isWebsocketErrorAtom } from '@/data/websocket/states';
import { useNotification } from '@/data/notification/hooks';
import { projectAtom } from '@/data/auth/project/states';
import { environmentAtom } from '@/data/fms/environment/states';
import { overrideTelemetryEmergencyStatus } from '@/data/fms/vehicle/utils';

// 最大リトライ回数
const MAX_RETRIES = 5;
// リトライ間隔の基本単位 (ミリ秒)
const BASE_DELAY = 1000;
// 追加される最大ジッター (ミリ秒)
const MAX_JITTER = 2000;

type channelType =
  | 'environmentVehiclesTelemetry'
  | 'environmentVehiclesMetadataUpdates'
  | 'vehicleActiveSchedule';

type Props<T extends channelType> = {
  channel: T;
};

type ResultType<T extends channelType> =
  T extends 'environmentVehiclesTelemetry'
    ? VehicleTelemetry
    : T extends 'environmentVehiclesMetadataUpdates'
      ? WebSocketVehicleMetadata
      : T extends 'vehicleActiveSchedule'
        ? Schedule
        : never;

export const useWebSocket = <T extends channelType>({ channel }: Props<T>) => {
  const [data, setData] = useState<ResultType<T> | null>(null);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const setIsWebsocketError = useSetRecoilState(isWebsocketErrorAtom);
  const prevSocket = usePrevious(socket);
  const retryCount = useRef<number>(0);
  const isError = useRef<boolean>(false);
  const resourceId = useRef<string | null>(null);
  const accessToken = useRef<string | null>(null);
  const isUserClose = useRef(false);
  const isVehicleChange = useRef(false);
  const getToken = useAuthToken();
  const { notifyError } = useNotification();

  // VehiclesTelemetry の場合
  const channelIsEnvironmentVehiclesTelemetry =
    channel === 'environmentVehiclesTelemetry';
  // VehiclesMetadata の場合
  const channelIsEnvironmentVehiclesMetadataUpdates =
    channel === 'environmentVehiclesMetadataUpdates';
  // vehicleActiveSchedule の場合
  const channelIsVehicleActiveSchedule = channel === 'vehicleActiveSchedule';

  const createSocket = useRecoilCallback(
    ({ snapshot }) =>
      async (vehicleId?: string | null) => {
        isUserClose.current = false;
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        const token = await getToken();
        const targetResourceId = channelIsVehicleActiveSchedule
          ? vehicleId
          : environment?.environment_id;
        if (!project || !targetResourceId || !token) {
          notifyError({
            message: 'WebSocketへの接続に失敗しました',
          });
          return;
        }

        resourceId.current = targetResourceId;
        accessToken.current = token.accessToken;

        const s = new WebSocket(
          `wss://${import.meta.env.VITE_FMS_API_DOMAIN}/${
            import.meta.env.VITE_FMS_API_VERSION
          }/ws?project_id=${project.id}&token=${token.accessToken}`,
        );

        if (!s) {
          notifyError({
            message: 'WebSocketへの接続に失敗しました',
          });
          return;
        } else {
          setSocket(s);
        }
      },
    [channelIsVehicleActiveSchedule, getToken, notifyError],
  );

  const closeSocket = useCallback(
    (doVehicleChange?: boolean) => {
      isUserClose.current = true;
      isVehicleChange.current = !!doVehicleChange;
      if (socket) {
        socket.close();
      }
    },
    [socket],
  );

  const onOpen = useCallback(() => {
    // 接続成功時にリトライカウントをリセット
    retryCount.current = 0;
    // 車両切り替えフラグをリセット
    isVehicleChange.current = false;
    // エラーフラグをリセット
    isError.current = false;
    setIsWebsocketError(false);
    if (!socket || !resourceId.current || !accessToken.current) {
      return;
    }
    console.group('FMS Websocket API Open');
    console.log(
      `%ctime: %c${DateTime.now().toFormat('HH:mm:ss')}`,
      'color: #2196f3;',
      'color: #66bb6a',
    );
    console.log(
      `%cchannel: %c${channel}`,
      'color: #2196f3;',
      'color: #fdd835 ',
    );
    console.log('%cresourceId:', 'color: #2196f3;', resourceId.current);
    console.groupEnd();
    socket.send(
      JSON.stringify({
        token: accessToken.current,
        operation: 'subscribe',
        channel,
        resource_id: resourceId.current,
      }),
    );
  }, [channel, setIsWebsocketError, socket]);

  const onMessage = useCallback(
    (e: MessageEvent) => {
      try {
        const updatedData = JSON.parse(e.data);
        // VehiclesTelemetry の場合
        if (channelIsEnvironmentVehiclesTelemetry) {
          updatedData?.status === 'shutdown' &&
            overrideTelemetryEmergencyStatus(updatedData);
          setData(updatedData);
          return;
        }
        // VehiclesMetadata の場合
        if (channelIsEnvironmentVehiclesMetadataUpdates)
          return setData(updatedData);
        // vehicleActiveSchedule の場合
        if (channelIsVehicleActiveSchedule) return setData(updatedData);
      } catch (err) {
        console.log(err);
      }
    },
    [
      channelIsEnvironmentVehiclesMetadataUpdates,
      channelIsEnvironmentVehiclesTelemetry,
      channelIsVehicleActiveSchedule,
    ],
  );

  const retryConnection = useCallback(() => {
    // 車両切り替え時かつ、エラーが無い場合はリトライして処理を終了
    if (isVehicleChange.current && !isError.current)
      return createSocket(resourceId?.current);
    // リトライ回数の最大値を超えた場合はログを出力して処理を終了
    if (retryCount.current >= MAX_RETRIES) {
      console.error(
        'WebSocket connection retry limit exceeded\nchannel:',
        channel,
      );
      setIsWebsocketError(true);
      return;
    }

    // バックオフ（リトライ回数^2 * 1000）
    const exponentialBackoff = Math.pow(2, retryCount.current) * BASE_DELAY;
    // ジッター（0以上1未満のランダム値 * 2000）
    const jitter = Math.random() * MAX_JITTER;
    const retryDelay = exponentialBackoff + jitter;
    // 時間を開けてリトライ
    setTimeout(() => {
      retryCount.current = retryCount.current + 1;
      createSocket(channelIsVehicleActiveSchedule ? resourceId?.current : null);
    }, retryDelay);
  }, [
    channel,
    channelIsVehicleActiveSchedule,
    createSocket,
    setIsWebsocketError,
  ]);

  const onError = useCallback(() => {
    isError.current = true;
  }, []);

  const onClose = useCallback(() => {
    if (!socket) return;
    console.group('FMS Websocket API Close');
    console.log(
      `%ctime: %c${DateTime.now().toFormat('HH:mm:ss')}`,
      'color: #2196f3;',
      'color: #66bb6a',
    );
    console.log(`%cchannel: %c${channel}`, 'color: #2196f3;', 'color: #f44336');
    console.log('%cresourceId:', 'color: #2196f3;', resourceId.current);
    console.groupEnd();
    socket.removeEventListener('open', onOpen);
    socket.removeEventListener('close', onClose);
    socket.removeEventListener('message', onMessage);
    socket.removeEventListener('error', onError);
    setSocket(null);
    if (!isUserClose.current) {
      retryConnection();
    }
  }, [socket, channel, onOpen, onMessage, onError, retryConnection]);

  useEffect(() => {
    if (!prevSocket && socket) {
      socket.addEventListener('open', onOpen);
      socket.addEventListener('close', onClose);
      socket.addEventListener('message', onMessage);
      socket.addEventListener('error', onError);
    }
  }, [socket, prevSocket, onOpen, onMessage, onClose, onError]);

  return {
    createSocket,
    closeSocket,
    data,
  };
};
